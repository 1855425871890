<template>
  <section class="w-100">
    <loading-spinner v-if="loading" />
    <div :class="style['events-wrapper']">
      <b-row class="mx-0">
        <b-col
          md="12"
          class="pull-left"
        >
          <h2 class="font-weight-bold mb-md-5 mb-4 mt-4 display-4 px-4 px-md-4">
            Events
          </h2>
        </b-col>
        <b-col cols="12">
          <b-tabs
            content-class="mt-5"
            :class="[style['month-tabs'], 'px-4 px-md-4']"
            align="start"
          >
            <b-tab
              v-for="(month, index) in months"
              :key="index"
              :title="month.name"
              :active="month.name == activeTab"
              @click="handleMonth(month)"
            />
          </b-tabs>
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <b-col md="12">
          <div class="pb-5">
            <template v-if="events != ''">
              <div
                v-for="(event, index) in events"
                :key="index"
              >
                <div class="pl-2 pt-3 pb-2">
                  <h5>{{ event[0].day }} - {{ event[0].titleDate }}</h5>
                </div>
                <template
                  v-for="(slot, date) in event"
                >
                  <event-card 
                    :key="date" 
                    :event="slot" 
                    :accountType="accountType"
                  />
                </template>
              </div>
            </template>
            <template v-else-if="loading == false">
              <div class="border mt-3 text-center py-5 mb-5 px-4"> 
                <div class="h4 font-weight-bold mt-2 py-5 mb-4">
                  No events found
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import LoadingSpinner from "../../components/Common/LoadingSpinner.vue";
import moment from "moment-timezone";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { _sendResponse } from "../../js/common-function";
import { APIUrlList } from "../../js/api_url";
import EventCard from "../../components/UpcomingEvents/EventCard.vue";
import style from "../../styles/upcoming-events.module.scss";

export default {
    components: {
        LoadingSpinner,
        EventCard
    },
    data() {
        return {
            start: new Date(),
            end: "",
            startMonth: "",
            startMonthYear: "",
            endMonth: "",
            endMonthYear: "",
            loading: false,
            months: [],
            startMonthName: "",
            activeTab: localStorage.getItem("upcomingEventMonth"),
            activeTabValue: localStorage.getItem("upcomingEventMonthYear"),
            events: "",
            accountType: localStorage.getItem("account-type"),
            style,
            userId: localStorage.getItem("userId"),
        };
    },
    created() {
        let start = new Date();
        this.start = moment(start).format("DD-MM-YYYY");
        let end = moment(start).add(1, "Y");
        this.startMonthYear = moment(start).format("MM-YYYY");
        this.endMonthYear = moment(start)
            .add(1, "Y")
            .subtract(1, "M")
            .format("MM-YYYY");
        this.startMonth = moment(start).month();
        this.startMonthName = moment(start).format("MMM");
        this.endMonth = end.month() - 1;
        //this.months[moment(start).format('MM-YYYY')] = moment(start).format('MMMM');

        for (var i = 0; i < 12; i++) {
            let date = moment(start).add(i, "M");
            let month = moment(date).format("MMMM");
            let monthYear = moment(date).format("MM-YYYY");
            let obj = {
                name: month,
                value: monthYear,
            };
            this.months.push(obj);
        }

        localStorage.setItem("upcomingEventMonth", this.startMonthName);
        localStorage.setItem("upcomingEventMonthYear", this.startMonthYear);
        let currentMonth = {};
        if (this.activeTab != "" && this.activeTab != "null" && this.activeTab != null){
            currentMonth = {
                name: this.activeTab,
                value: this.activeTabValue
            };
        } else {
            currentMonth = {
                name: this.startMonthName,
                value: this.startMonthYear
            };
        }
        this.handleMonth(currentMonth);
    },
    methods: {
        handleMonth(month) {
            localStorage.setItem("upcomingEventMonth", month.name);
            localStorage.setItem("upcomingEventMonthYear", month.value);
            this.activeTab = month.name;
            this.activeTabValue = month.value;
            let events = this.getUpcomingEvents(month.value);
            this.events = events;
        },
        getUpcomingEvents(monthYear) {
            this.loading = true;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            let formData = {
                monthYear: monthYear,
            };
            axios
                .post(API_BASE_URL + APIUrlList.UPCOMINGEVENTS, formData)
                .then((response) => {
                    var array = response.data.data;
                    let events = [];
                    if (array && array.length) {
                        array.map((value) => {
                            var obj = {};
                            var start_date = new Date(value.start_time.replace(/-/g, "/")); //+' UTC'
                            if (
                                localStorage.getItem("account-type") !== null &&
                localStorage.getItem("account-type") == 2
                            ) {
                                start_date = new Date(value.start_time.replace(/-/g, "/"));
                            }

                            start_date = start_date.toString();

                            var today = moment().format("DD/MM/YY");
                            var video_call_date = moment(start_date).format("DD/MM/YY");
                            var weekDayName = moment(start_date).format("dddd");
                            if (today === video_call_date) {
                                weekDayName = "Today";
                            }

                            obj["day"] = weekDayName;
                            var formattedTitleDate =
                moment(start_date).format("MMMM DD, YYYY");
                            obj["titleDate"] = formattedTitleDate;
                            obj["full_date"] = moment(start_date).format("YYYY-MM-DD");
                            obj["start_time"] = moment(start_date).format("h:mm A");
                            obj["slot_id"] = value.booking ? value.booking.slot_id : "";
                            if ((this.accountType === "1" && value.fab) || (this.accountType === "2" && this.userId != value.user_id && value.fab)) {
                                obj["fab"] = value.fab;
                            } 
                            var dateToday = new Date();
                            obj["date_today"] = moment(dateToday).format("YYYY-MM-DD");

                            var call_type_name = "Video Call";
                            if (value.call_type == 2) {
                                call_type_name = "Live Streaming";
                            }
                            obj["call_type_name"] = call_type_name;
                            obj["call_type"] = value.call_type;
                            obj["stream_type"] = value.stream_type;
                            obj["duration"] = this.duration(value.start_time, value.end_time);
                            let date1 = new Date();
                            obj["remaining_time"] = this.duration(date1, value.start_time);
                            obj["booking"] = value.booking;
                            obj["user_id"] = value.user_id;
                            events.push(obj);
                        });
                        var mainArr = this.groupBy(events, "full_date");
                        events = mainArr;
                    } 
                    this.events = events;
                    this.loading = false;
                })
                .catch((error) => {
                    // handle authentication and validation errors here
                    this.loading = false;
                    _sendResponse("error", error);
                });
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        duration(start, end){
            let date1 = moment(start).format("DD-MM-YYYY HH:mm:ss");
            let date2 = moment(end).format("DD-MM-YYYY HH:mm:ss");

            var startTime = moment(date1, 'DD-MM-YYYY hh:mm:ss');
            var endTime = moment(date2, 'DD-MM-YYYY hh:mm:ss');
            var minsDiff = endTime.diff(startTime, 'minutes');
            var secsDiff = endTime.diff(startTime, 'seconds');
            var hours = minsDiff/60;
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            var hoursDiff = "";
            if (rhours > 1){
                hoursDiff = rhours + ' hours';
            } else {
                hoursDiff = rhours + ' hour';
            }
            if (rminutes>0){
                hoursDiff = hoursDiff+" "+rminutes;
                if (rminutes> 1){
                    hoursDiff = hoursDiff + ' minutes';
                } else {
                    hoursDiff = hoursDiff + ' minute';
                }
            }
            
            //var sec = moment.utc(endTime.diff(startTime)).format("HH:mm:ss");
            return {
                hours: hoursDiff,
                mins: minsDiff,
                seconds: secsDiff
            };
        },
    },
};
</script>
