<template>
  <div
    :class="['shadow mx-2 text-center my-2', style['event-card-wrap'],!event.fab ? style['no-image']: '']"
  >
    <div :class="[style['event-img-separator']]" />
    <template v-if="event.fab">
      <div
        :class="[style['img-wrapper']]"
      >
        <img
          :src="event.fab.profile_pic_url"
          @error="setPlaceholderProfileImg"
        >
      </div>
    </template>
    <div class="text-left px-3">
      <div class="my-2">
        <icon-videocall
          v-if="event.call_type == 1"
          width="24"
          height="24"
          class="mr-2"
        />
        <icon-streaming
          v-if="event.call_type == 2"
          width="24"
          height="24"
          class="mr-2"
        />
        <small 
          v-if="event.call_type == 2"
          class="text-gray"
        >{{
          event.stream_type == 1 ? "Private " : "Public " 
        }}</small>
        <small class="text-gray">{{
          event.call_type_name
        }}<span v-if="event.user_id == userId"> with fans</span></small>
      </div>
      <div v-if="event.fab">
        <h5>{{ event.fab.name }} {{ event.fab.surname }}</h5>
      </div>
      <div>
        <p>{{ event.titleDate }} | {{ event.start_time }}</p>
      </div>
      <div>
        <icon-clock
          width="24"
          height="24"
        />
        <span class="text-gray">
          Upto {{ event.duration.mins > 60 ? event.duration.hours : event.duration.mins+ (event.duration.mins > 1 ? " minutes" : " minute" ) }}
        </span>
      </div>
      <div>
        <b-button 
          pill
          class="btn btn-golden text-white w-100 my-4"
          :disabled="event.remaining_time.mins > 5"
          @click="handleCall(event)"
        >
          {{ accountType == 1 ? 'Join' : 'Start' }} Call
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import style from ".././../styles/event-card.module.scss";
import { setPlaceholderProfileImg } from "../../js/common-function";
import iconVideocall from "../../../src/assets/svg-import/videocall.svg";
import iconStreaming from "../../../src/assets/svg-import/livestream.svg";
import iconClock from "../../../src/assets/svg-import/clock.svg";
import { API_IMAGE_URL } from "../../config/config";

export default {
    components: {
        iconVideocall,
        iconStreaming,
        iconClock
    },
    props: {
        event: {
            type: Object,
            default: () => {},
        },
        accountType: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            style,
            setPlaceholderProfileImg,
            API_IMAGE_URL,
            userId: localStorage.getItem("userId")
        };
    },
    methods: {
        handleCall(data) {
            this.$router.push({ path: `/call/${data.booking.id}` });
        },
    }
};
</script>
